import { sendRequest } from "../utility/utility";
import { getItem } from "../utility/localStorageControl";

const getHomeEvents = async (filter, search, page) => {
  const route = `home/events?page=${page}&filter=${filter}${search ? `&search=${search}` : ''}`;
  const token = getItem("token");
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
  };
  return await sendRequest(route, request);
}

const getBenefitEvents = async (page, search='') => {
  const route = `home/benefits?page=${page}${search ? `&search=${search}` : ''}`;
  const token = getItem("token");
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
  };
  return await sendRequest(route, request);
}
const getOtherEvents = async (page, search) => {
  const route = `home/other_events?page=${page}${search ? `&search=${search}` : ''}`;
  const token = getItem("token");
  const request = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
  };
  return await sendRequest(route, request);
}

const addToFavorites = async (eventID) => {
  const route = `favorite/${eventID}`;
  const token = getItem("token");
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
      Accept: "application/json"
    },
  };
  return await sendRequest(route, request);
}

const getQuestionLevel3CCT = async (cct) => {
  const token = getItem("token"),
    route = `questions/lvl3/2/${cct}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
        Accept: "application/json"
      },

    };
  return await sendRequest(route, request);
}
const getParticipantsCountByEvents = async (ids = []) => {
  const params = new URLSearchParams();
  ids.forEach((id, i) => {
    params.append(`ids[${i}]`, id);
  });

  const token = getItem("token");
  
  const route = `home/events/participants?${params.toString()}`;
  const request = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      'Authorization': `Bearer ${token}`,
    },
  };
  return await sendRequest(route, request);
}

const getFileFolioRegisterToEvent = async (folio) => {
  const token = getItem("token"),
    route = `event/folio/${folio}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
        Accept: "application/json"
      },

    };
  return await sendRequest(route, request, true);
}

const getFileFolioMultipleRegister = async (id) => {
  const token = getItem("token"),
    route = `generatepdffolio/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },

    };
  return await sendRequest(route, request, true);
}

const getFileFolioMultipleRegisterOnSubEvent = async (id) => {
  const token = getItem("token"),
    route = `generatepdffolioSubeventMultipleUsers/${id}`,
    request = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },

    };
  return await sendRequest(route, request, true);
}

export{
  getHomeEvents,
  addToFavorites,
  getBenefitEvents,
  getOtherEvents,
  getQuestionLevel3CCT,
  getParticipantsCountByEvents,
  getFileFolioRegisterToEvent,
  getFileFolioMultipleRegister,
  getFileFolioMultipleRegisterOnSubEvent
}
