<template>
  <div class="home">
    <div class="home--banner">
      <img :src="require(`@/static/img_events/Banner.jpg`)" alt="img-banner" />
      <div class="text recrea-text">
        <span class="recrea-text-re">Re</span><span class="recrea-text-crea">Crea</span>
        <span class="recrea-text-eventos"> Eventos</span>
        <span v-if="showSandboxAlert" class="recrea-text-pruebas"> Pruebas</span>
      </div>
      <div class="text banner-text">Conoce los eventos que <br />creamos pensando sólo en ti</div>
    </div>
    <div class="home--events">
      <div class="events__tabs">
        <a-row :gutter="25" style="margin-bottom: 1rem">
          <a-col :xs="24" :sm="12" :md="8" :lg="4" style="margin-bottom: 1rem">
            <span @click="onChangeTab('upcoming')" :class="filter == 'upcoming' ? 'selected' : ''"
              >Próximos eventos
            </span>
          </a-col>
          <a-col :xs="24" :sm="12" :md="8" :lg="4" style="margin-bottom: 1rem">
            <span @click="onChangeTab('online')" :class="filter == 'online' ? 'selected' : ''">Eventos en línea</span>
          </a-col>
          <a-col :xs="24" :sm="12" :md="8" :lg="4" style="margin-bottom: 1rem">
            <span @click="onChangeTab('Face-to-face')" :class="filter == 'Face-to-face' ? 'selected' : ''"
              >Eventos presenciales</span
            >
          </a-col>
          <a-col :xs="24" :sm="12" :md="8" :lg="4" style="margin-bottom: 1rem">
            <span @click="onChangeTab('past')" :class="filter == 'past' ? 'selected' : ''">Eventos anteriores</span>
          </a-col>
          <a-col :xs="24" :sm="24" :md="12" :lg="5">
            <a-input
              v-on:keyup.enter="searchEvents()"
              v-model:value="search"
              placeholder="Buscar evento"
              class="input-field"
            >
              <template #prefix>
                <sdFeatherIcons @click.stop="getEvents()" type="search" size="14" />
              </template>
            </a-input>
          </a-col>
        </a-row>
      </div>
      <div v-if="events.length > 0" class="events__grid">
        <a-row :gutter="25">
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <a-row :gutter="25">
              <a-col
                v-for="(event, indexEvent) in events"
                :key="event.id"
                :xs="24"
                :sm="20"
                :md="14"
                :lg="13"
                :xl="7"
                style="margin: 1rem !important; margin-right: 0;margin-left: 1rem;"
              >
                <div class="event--card">
                  <div class="card__img">
                    <img
                      :src="event.front_page ? base_url + event.front_page : 'https://via.placeholder.com/448x248'"
                      width="{448}"
                      height="{248}"
                      alt="imagen"
                      @click.stop="sendToEventDetails(event.id)"
                    />
                  </div>
                  <div class="card__main">
                    <p class="card__title" @click.stop="sendToEventDetails(event.id)">{{ event.title }}</p>
                    <p class="card__description">{{ event.description }}</p>
                    <p class="card__date">{{ formatDate(event.event_date) }}, {{ event.event_hour }}hrs.</p>
                  </div>
                  <div class="line" />
                  <div class="card__footer">
                    <a-row :gutter="0" style="width: 100% !important; float: right;">
                      <a-col class="mt-2 mb-2" :xxl="8" :lg="8" :md="12" :xs="12">
                        <span class="registers">
                          <img
                            :src="require(`@/static/img_events/SEJ_Eventos_asistentes.svg`)"
                            alt="logo"
                            width="16"
                            height="16"
                          />
                          {{ `${event.participants_count == undefined ? 0 : event.participants_count} registros` }}
                        </span>
                      </a-col>
                      <a-col class="mt-2 mb-2" :xxl="9" :lg="9" :md="12" :xs="12">
                        <router-link
                          :to="
                            event.grouped_events ? `/home/events/${event.id}` : `/home/register/participant/${event.id}`
                          "
                          v-if="
                            dateIsAvailableToRegister(event) &&
                              filter !== 'past' &&
                              (!event.already_registered || (event.grouped_events && event.subevents_allowed.length)) &&
                              (!event.director_register || userRoles[0].nombre === 'Director de CCT') &&
                              (!event.only_admin_register ||
                                removeAccents(userRoles[0].nombre).toLowerCase() === 'administrador de area') &&
                              (!event.amount_participants ||
                                (event.amount_participants && event.participants_count < event.amount_participants))
                          "
                        >
                          <button class="btn-register">Registrarme</button>
                        </router-link>
                      </a-col>
                      <a-col class="mt-2 mb-2" :xxl="4" :lg="4" :md="12" :xs="12">
                        <sdDropdown
                          class="dropdown_three-dot-button"
                          :action="['click']"
                          v-if="dateIsAvailableToRegister(event) && filter !== 'past' &&
                          (!event.amount_participants || (event.amount_participants && event.participants_count < (event.amount_participants))) &&
                          (showRegisterAssistants(event) || showRegisterParticipants(event) || showRegisterAsAssitant(event))"
                        >
                          <template #overlay>
                            <div class="dropdown-content">
                              <router-link
                                :to="event.grouped_events
                                ? `/home/events/${event.id}?singleRegister=1`
                                : `/home/register/assistant/${event.id}`"
                                v-if="showRegisterAsAssitant(event)"
                              >
                                <button class="btn-dropdown">
                                  <img
                                    :src="require(`@/static/img_events/SEJ_Eventos_asistentes.svg`)"
                                    alt="logo" width="18" height="18"
                                  />
                                  Registrarme como asistente
                                </button>
                              </router-link>


                              <router-link :to="event.grouped_events
                              ? `/home/events/${event.id}?registerpartbycct=1`
                              : `/home/registers/participants/${event.id}`"
                                  v-if="showRegisterParticipants(event)"
                              >
                                <button class="btn-dropdown">
                                    <img
                                      :src="require(`@/static/img_events/Eventos_registrar_participantes.svg`)"
                                      alt="logo" width="18" height="18"
                                    />
                                    Registrar participantes
                                </button>
                              </router-link>
                              <router-link
                                :to="event.grouped_events
                                ? `/home/events/${event.id}?registerassabycct=1`
                                : `/home/registers/assistants/${event.id}`"
                                v-if="showRegisterAssistants(event)"
                              >
                                <button class="btn-dropdown">
                                    <img
                                      :src="require(`@/static/img_events/Eventos_registrar_asistentes.svg`)"
                                      alt="logo" width="18" height="18"
                                    />
                                    Registrar asistentes
                                </button>
                              </router-link>
                            </div>
                          </template>
                          <button @click.stop class="btn-options">
                            <img :src="require('@/static/img_events/SEJ_Eventos_opciones-21.svg')" alt="opciones" />
                          </button>
                        </sdDropdown>
                      </a-col>
                      <a-col class="mt-2 mb-2" :xxl="3" :lg="3" :md="12" :xs="12">
                        <button
                          class="btn-favorite"
                          @click.stop="toggleFavorite(event.id, indexEvent)"
                          v-if="filter !== 'past'"
                        >
                          <img
                            v-if="!event.favorite"
                            :src="require('@/static/img_events/SEJ_Eventos_agregar a me interesa.svg')"
                            alt="me interesa"
                          />
                          <img
                            v-else
                            :src="require('@/static/img_events/SEJ_Eventos_me interesa.svg')"
                            alt="me interesa"
                          />
                        </button>
                      </a-col>
                    </a-row>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row :gutter="24" class="btn-view-more" @click.stop="handleViewMore()">
          <p v-if="page + 1 != totalPages">Ver más...</p>
        </a-row>
      </div>
      <a-row :gutter="25" v-else>
        <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;" v-if="!searched">
          <span class="no-events-label">No hay eventos que mostrar</span>
        </a-col>
        <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;" v-else>
          <span class="no-events-label">Ningún resultado encontrado</span>
        </a-col>
      </a-row>
    </div>
    <div class="home--events">
      <div class="events__tabs" style="margin-top: 3rem">
        <a-row :gutter="25" style="margin-bottom: 1rem">
          <a-col :xs="12" :sm="12" :md="8" :lg="4">
            <span
              @click="onChangeTabSecundary('benefits')"
              :class="filterEventsSecundaries == 'benefits' ? 'selected' : ''"
              >Beneficios
            </span>
          </a-col>
          <a-col :xs="12" :sm="12" :md="8" :lg="4">
            <span @click="onChangeTabSecundary('others')" :class="filterEventsSecundaries == 'others' ? 'selected' : ''"
              >Otros Eventos</span
            >
          </a-col>
        </a-row>
      </div>
      <div v-if="eventsSecundaries.length > 0" class="events__grid">
        <a-row :gutter="25">
          <a-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <a-row :gutter="25">
              <a-col
                v-for="(event, indexEvent) in eventsSecundaries"
                :key="event.id"
                :xs="24"
                :sm="18"
                :md="12"
                :lg="10"
                :xl="7"
                style="margin: 1rem !important; margin-right: 0;margin-left: 1rem;"
              >
                <div class="event--card">
                  <div class="card__img" @click.stop="sendToEventDetails(event.id)">
                    <img
                      :src="event.front_page ? base_url + event.front_page : 'https://via.placeholder.com/448x248'"
                      width="{448}"
                      height="{248}"
                      alt="imagen"
                    />
                  </div>
                  <div class="card__main" @click.stop="sendToEventDetails(event.id)">
                    <p class="card__title">{{ event.title }}</p>
                    <p class="card__description">{{ event.description }}</p>
                    <p class="card__date">{{ formatDate(event.event_date) }}, {{ event.event_hour }}hrs.</p>
                  </div>
                  <div class="line" />
                  <div class="card__footer">
                    <a-row :grutter="25" style="width: 100% !important; float: right;">
                      <br /><br />
                      <a-col class="mt-2 mb-2" :xxl="24" :lg="24" :md="24" :xs="24">
                        <button
                          class="btn-favorite"
                          @click="toggleFavoriteSecundaries(event.id, indexEvent)"
                          style="float: right;"
                        >
                          <img
                            :src="
                              event.favorite
                                ? require('@/static/img_events/SEJ_Eventos_me interesa.svg')
                                : require('@/static/img_events/SEJ_Eventos_agregar a me interesa.svg')
                            "
                            alt="me interesa"
                          />
                        </button>
                      </a-col>
                      <br /><br />
                    </a-row>
                  </div>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
        <a-row
          v-if="pageSecundary + 1 != totalPagesSecundaries"
          :gutter="24"
          class="btn-view-more"
          @click="handleViewMoreSecundary()"
        >
          <p>Ver más...</p>
        </a-row>
      </div>
      <a-row :gutter="25" v-else>
        <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;" v-if="!searched">
          <span class="no-events-label">No hay eventos que mostrar</span>
        </a-col>
        <a-col :xxl="24" :lg="24" :md="24" :xs="24" style="text-align: center;" v-else>
          <span class="no-events-label">Ningún resultado encontrado</span>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  getHomeEvents,
  addToFavorites,
  getOtherEvents,
  getBenefitEvents,
  getParticipantsCountByEvents,
} from '../../services/home';
import { formatDate, removeAccents } from '@/utility/utility';
import { getItem } from '../../utility/localStorageControl';

let timer = null;

const showSandboxAlert = process.env.VUE_APP_ENVIRONMENT === 'pruebas';

export default {
  name: 'Event',
  data() {
    return {
      filter: 'upcoming',
      filterEventsSecundaries: 'benefits',
      search: '',
      searched: false,
      events: [],
      eventsSecundaries: [],
      eventsFaceToFace: [],
      page: 0,
      pageSecundary: 0,
      totalPages: 0,
      totalPagesSecundaries: 0,
      base_url: process.env.VUE_APP_BASE_URL,
      showSandboxAlert,
    };
  },
  mounted() {
    this.getEvents();
    this.getEventsSecundaries();
    this.getEventsFaceToFace();
  },
  beforeMount() {
    timer = setInterval(() => {
      let ids = this.events.map(event => event.id);
      ids.concat(this.eventsSecundaries.map(event => event.id));
      getParticipantsCountByEvents(ids)
        .then(response => {
          if (response.success) {
            this.events.forEach(event => {
              event.participants_count = response.data[event.id];
            });
            this.eventsSecundaries.forEach(event => {
              event.participants_count = response.data[event.id];
            });
          }
        })
        .catch(() => {});
    }, 30000);
  },
  methods: {
    showRegisterParticipants(event) {
      return (
        (this.userRoles[0].nombre === 'Director de CCT' && (!event.only_admin_register || event.director_register)) ||
        (removeAccents(this.userRoles[0].nombre).toLowerCase() === 'administrador de area' &&
          (!event.director_register || event.only_admin_register))
      );
    },
    showRegisterAssistants(event) {
      return (
        event.assistants &&
        ((this.userRoles[0].nombre === 'Director de CCT' && (!event.only_admin_register || event.director_register)) ||
          (removeAccents(this.userRoles[0].nombre).toLowerCase() === 'administrador de area' &&
            (!event.director_register || event.only_admin_register)))
      );
    },
    showRegisterAsAssitant(event) {
      return (
        event.assistants &&
        !event.already_registered &&
        (!event.director_register || this.userRoles[0].nombre === 'Director de CCT') &&
        (!event.only_admin_register ||
          removeAccents(this.userRoles[0].nombre).toLowerCase() === 'administrador de area')
      );
    },
    searchEvents() {
      this.getEvents();
      this.getEventsSecundaries();
      this.getEventsFaceToFace();
      if (!this.search) {
        this.searched = false;
      } else {
        this.searched = true;
      }
    },
    async getEvents() {
      let events = [];
      await getHomeEvents(this.filter, this.search, this.page + 1).then(response => {
        if (response.success) {
          events = this.page > 0 ? this.events.concat(response.data.data) : response.data.data;
          this.totalPages = response.data.last_page;
        } else {
          events = [];
        }
      });
      this.events = events;
    },
    async getEventsSecundaries() {
      let events = [];
      if (this.filterEventsSecundaries === 'benefits') {
        await getBenefitEvents(this.pageSecundary + 1, this.search).then(response => {
          if (response.success) {
            events = this.pageSecundary > 0 ? this.eventsSecundaries.concat(response.data.data) : response.data.data;
            this.totalPagesSecundaries = response.data.last_page;
          } else {
            events = [];
          }
        });
        //return events
      } else {
        await getOtherEvents(this.pageSecundary + 1, this.search).then(response => {
          if (response.success) {
            events = this.pageSecundary > 0 ? this.eventsSecundaries.concat(response.data.data) : response.data.data;
            this.totalPagesSecundaries = response.data.last_page;
          } else {
            events = [];
          }
        });
        //return events
      }

      this.eventsSecundaries = events;
    },
    async getEventsFaceToFace() {
      let events = [];
      await getHomeEvents('Face-to-face', this.search, this.page + 1).then(response => {
        if (response.success) {
          events = response.data.data.map(event => event.id);
          events = this.page > 0 ? this.eventsFaceToFace.concat(events) : events;
        } else {
          events = [];
        }
        this.eventsFaceToFace = events;
      });
    },
    onChangeTab(name) {
      this.filter = name;
      this.page = 0;
      this.getEvents();
    },
    onChangeTabSecundary(name) {
      this.filterEventsSecundaries = name;
      this.pageSecundary = 0;
      this.getEventsSecundaries();
    },
    handleViewMore() {
      this.page += 1;
      this.getEvents();
      this.getEventsFaceToFace();
    },
    handleViewMoreSecundary() {
      this.pageSecundary += 1;
      this.getEventsSecundaries();
    },
    sendToEventDetails(idEvent) {
      this.router.push({ name: 'eventDetail', params: { id: idEvent } });
    },
    async toggleFavorite(idEvent, indexEvent) {
      await addToFavorites(idEvent).then(response => {
        if (response.success) {
          this.events[indexEvent].favorite = !this.events[indexEvent].favorite;
        }
      });
    },
    async toggleFavoriteSecundaries(idEvent, indexEvent) {
      await addToFavorites(idEvent).then(response => {
        if (response.success) {
          this.eventsSecundaries[indexEvent].favorite = !this.eventsSecundaries[indexEvent].favorite;
        }
      });
    },
    handleMenuClick() {},
    dateIsAvailableToRegister(event) {
      let currentDate = new Date();
      let startRegisterDate = new Date(event.start_register_date);
      let endRegisterDate = new Date(event.end_register_date);

      currentDate.setTime(currentDate.getTime() + currentDate.getTimezoneOffset() * 60 * 1000); //Acomodar el offset del horario
      currentDate.setHours(0, 0, 0, 0);
      startRegisterDate.setTime(startRegisterDate.getTime() + startRegisterDate.getTimezoneOffset() * 60 * 1000); //Acomodar el offset del horario
      endRegisterDate.setTime(endRegisterDate.getTime() + endRegisterDate.getTimezoneOffset() * 60 * 1000); //Acomodar el offset del horario

      return currentDate >= startRegisterDate && currentDate <= endRegisterDate;
    },
  },
  async setup() {
    const { dispatch } = useStore();
    const router = useRouter();
    const userRoles = getItem('roles');
    userRoles[0].nombre = userRoles[0].nombre
      .normalize('NFD')
      .replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi, '$1$2')
      .normalize();
    dispatch('changeTitleHeader', '');
    return {
      formatDate,
      router,
      userRoles,
      removeAccents,
    };
  },
  beforeUnmount: () => {
    if (timer) {
      clearInterval(timer);
    }
  },
};
</script>

<style scoped lang="sass">
.home
  padding: 20px 32px

  .home--banner
    border-radius: 20px
    position: relative
    height: 300px
    text-align: right
    color: white
    @media (min-width: 1880px)
      height: 480px
    img
      border-radius: 20px
      width: 100%
      height: 100%
      object-fit: cover
    .recrea-text
      text-align: left
      position: absolute
      top: 8px
      left: 16px !important
      right: unset !important
    .recrea-text-re
      font-family: "Nutmeg Headline UltraBlack"
      color: #E7004C
    .recrea-text-crea
      font-family: "Nutmeg Headline BookItalic"
    .recrea-text-eventos
      font-family: "Nutmeg Headline ExtraBold"
    .recrea-text-pruebas
      font-family: "Nutmeg Headline ExtraBold"
    .banner-text
      font-family: "Nutmeg Black" !important
      color: #1d1d1b !important
      text-shadow: 0px 0px 2px #cccccc
      position: absolute
      top: unset !important
      bottom: 8px !important
      font-size: 42px !important
      @media (max-width: 1700px)
        font-size: 34px !important
      @media (max-width: 1428px)
        font-size: 30px !important
      @media (max-width: 1000px)
        font-size: 25px !important
      @media (max-width: 700px)
        font-size: 22px !important
      @media (max-width: 350px)
        font-size: 18px !important
    .text
      position: absolute
      top: 8px
      right: 16px
      font-size: 52px
      font-family: "Nutmeg Bold"
      color: white
      // @media (min-width: 1428px)
      //   font-size: 3vw
      @media (max-width: 1700px)
        font-size: 44px
      @media (max-width: 1428px)
        font-size: 40px
      @media (max-width: 1000px)
        font-size: 35px
      @media (max-width: 700px)
        font-size: 30px
      @media (max-width: 350px)
        font-size: 28px

  .home--events
    margin-top: 5rem
    .btn-view-more

      font-size: 18px
      color: #3FA7E1
      display: flex
      justify-content: center

      &:hover
        cursor: pointer
        text-decoration: underline

    .events__tabs

      span
        color: #989898
        font-size: 17px
        font-family: 'Nutmeg Bold'
        padding-bottom: 5px
        letter-spacing: 0
        &.selected
          color: #404040
          border-bottom: 3px solid #3FA7E1
        &:hover
          cursor: pointer
      .input-field
        height: 40px




    .events__grid
      padding: 0.5rem
      margin-left: 1rem
      overflow: scroll
      .event--card
        box-shadow: 0px 3px 6px #00000029
        border-radius: 20px
        background: white
        .card__img
          border-radius: 20px 20px 0px 0px
          img
            border-radius: 20px 20px 0px 0px
            object-fit: cover
            width: 100%
            height: 248px
          &:hover
            cursor: pointer


      .card__main
        padding: 15px 32px 0px 32px
        .card__title
          color: #404040
          font-size: 18px
          font-family: 'Avenir Heavy'
          overflow: hidden
          display: -webkit-box !important
          -webkit-line-clamp: 3
          -webkit-box-orient: vertical
          &:hover
            cursor: pointer

        .card__description
          color: #7B868C
          font-size: 14px
          font-family: "Avenir Roman"
          text-align: left
          min-height: 110px
          overflow: hidden
          display: -webkit-box !important
          -webkit-line-clamp: 5
          -webkit-box-orient: vertical
          white-space: normal

        .card__date
          color: #51AF2C
          font-size: 12px
          font-family: 'Avenir Heavy'



      .line
        border-bottom: 1px solid #CCCCCC
        margin-bottom: 10px

      .card__footer
        padding: 0px 20px 15px 20px
        display: flex
        //grid-template-columns: 1fr 1fr 1fr 1fr
        align-items: center
        width: 100%
        gap: 10px
        @media only screen and (min-width: 1024px)
          gap: 20px

        .registers
          //justify-self: flex-start
          color: #7B868C
          font-size: 11px
          font-family: 'Avenir Heavy'

          min-width: 75px

        .btn-register
          //justify-self: center
          background-color: #E8394D
          color: white
          font-family: 'Avenir Heavy'
          font-size: 13px
          border-radius: 24px
          border: 1px solid #E8394D
          width: 96px
          height: 30px
          text-align: center
          padding: 2px
          display: flex
          justify-content: center
          align-items: center
          &:hover
            cursor: pointer
        .btn-options

            background-color: transparent
            border: 0
            margin-top: .5rem
            width: 30px
            img
              width: 30px
              height: 30px
            &:hover
              cursor: pointer

        .btn-options
          background-color: transparent
          border: none
          margin-top: 0
          margin-right: 20px
          img
            width: 34px
            height: 34px
            @media only screen and (max-width: 1400px)
              width: 30px
              height: 30px
          &:hover
            cursor: pointer

        .btn-favorite
          //justify-self: flex-end
          background-color: #F9CDD2
          border: 1px solid #F9CDD2
          border-radius: 50%
          width: 32px
          height: 32px
          display: flex
          justify-content: center
          align-items: center
          img

            width: 24px
            height: 19px
          &:hover
            cursor: pointer


.input-field
  border-radius: 30px
.pointer
  cursor: pointer

.dropdown-content
  width: 100%
  .btn-dropdown
    width: 100%
    background-color: transparent
    border: none
    color: #404040
    text-align: left
    padding: 0 .5rem
    font-family: Avenir Heavy
    font-size: 14px
    font-weight: bold
    &:hover
      cursor: pointer
</style>

<style lang="sass">
.dropdown_three-dot-button
  border: 1px solid #CCCCCC
  border-radius: 20px
  box-shadow: 0px 3px 6px #00000029
  width: 276px
</style>
